import React, { useState, useCallback, useRef, useEffect } from 'react';
import { css, keyframes } from '@emotion/react';
import { wrapper, IconButton } from '../styles/elements';
import Link from '../../link';
import { color } from "../styles/theme"
import SALogo from "../assets/logos/expo9_pri_wht_rgb.svg"
import SAPride from "../../../../static/media/expo_pri_pride_rgb.svg"
import mq from '../styles/mediaquerys';
import navigation from '../../../data/navigation';
import Flags from '../../../data/raw/flags';
import PlueLoginButton from './plueLoginButton';

export const getProps = ({ isPartiallyCurrent, href, location }) => {
  return {
    ...(isPartiallyCurrent && href !== "/"
      ? {
          "data-active": true,
        }
        : (href === "/" && location.pathname === "/") ? 
        {
          "data-active": true,
        }
        : (href === "/people/all" && location.pathname.includes("/people/") && !(location.pathname.includes("speakers"))) ? 
        {
          "data-active": true,
        }
        : (href === "/agenda" && location.pathname.includes(`/${Flags.settings.protectedArea.url}/agenda`)) ? 
        {
          "data-active": true,
        }
        : (href === "/startups" && location.pathname.includes(`/${Flags.settings.protectedArea.url}/startups`)) ? 
        {
          "data-active": true,
        }
      : {}),
  }
}

const PreHeader = ({dark, preHeaderRef}) => {
  
  const Info = () => {
    
    const blink = keyframes`
      from {
        opacity: 1.0;
      }
      50% {
        opacity: .1;
      }
      to {
        opacity: 1.0;
      }
    ` 
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          margin: .75em 0;
          ${mq[1]} {
            white-space: nowrap;
            min-width: 0;
            width: auto;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            min-width: 0;
            flex-grow: 0;
            margin-right: 1.25em;
            margin-left: -1.25rem;
            padding-left: 1.25em;
            padding-right: 1.25em;
            mask-image: ${"linear-gradient(to right,transparent," +
            color.richBlack +
            " 1.25rem," +
            color.richBlack +
            " 96%,transparent)"};
            ${mq[0]} {
              align-items: center;
            }
          `}
        >
          <span
            css={[css`
              line-height: 1;
              padding: 0.125rem 0.5rem;
              text-transform: uppercase;
              display: inline-flex;
              align-items: center;
              letter-spacing: 0.075em;
              font-weight: 600;
              border-radius: 3px;
              background: ${dark ? color.plue100 : color.plue25};
              color: ${color.plue400};
              min-height: 1.5rem;
              margin: 0.1em 0.75em 0.1em 0;
              white-space: nowrap;
              font-size: 0.7em;
            `, Flags.features.live ? (
              css`
                animation: ${blink} 2s ease-in-out infinite;
              ` 
            ):null]}
          >
            {navigation.preheader.info.block}
          </span>
          <div
            css={css`
              p {
                margin-bottom: 0;
                line-height: 1.3em;
              }
              button {
                color: ${dark ? color.gray200 : color.gray800};
              }
            `}
          >{navigation.preheader.info.content}</div>
        </div>
      </div>
    )
  }


  const Nav = () => {

    return (
      <nav css={css`
        display: none;
        flex-shrink: 0;
        margin-left: auto;
        ${mq[2]} {
          display: flex;
        }
      `}>
        <ul css={css`
          margin: 0;
          list-style: none;
          display: flex;
          li {
            margin: 0;
          }
          a {
            padding: 0.25em 0.5em;
          }
        `}>
          {navigation.preheader.navigation.map((el, index) => (
            <React.Fragment key={index}>
               <li>
                <Link
                  to={el.url}
                >{el.title}</Link>
              </li>
            </React.Fragment>
          ))}
          <li>
            <PlueLoginButton css={css`
              display: inline-block;
              margin-left: 1em;
              padding-left: 1em;
              svg {
                stroke: ${color.gray400};
              }
              &:before {
                border: none;
                background: none;
              }
            `} />
          </li>
        </ul>
      </nav>
    )
  }

  return (
    <aside ref={preHeaderRef} css={css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 3em;
      padding-top: 1em;
      a, p {
        color: ${dark ? color.gray200 : color.gray800};
        font-size: .7rem;
        text-decoration: none;
        letter-spacing: .03em;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
        font-smoothing: auto;
      }
      &:before {
        content: '';
        position: absolute;
        background: ${dark ? color.gray800 : color.gray25};
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
      }
    `}>
      
      <Info />
      <Nav />
    </aside>
  )
}

const Header = ({dark, dimensions}) => {

  const Logo = () => {

    return (
      <div
        css={css`
          padding: 1rem 0;
          display: flex;
          position: relative;
          ${mq[2]} {
            justify-content: flex-start;
          }
          svg {
            vertical-align: middle;
          }
        `}
      >
        <Link
          to="/"
          css={css`
            line-height: 1em;
            margin-right: 1.25em;
            svg {
              height: 20px;
              fill: ${dark ? color.white : color.main_dark};
            }
            img {
              height: 22px;
              margin-bottom: 0;
              width: auto;
            }
          `}
        >
          {dark ? (<img src={SAPride} alt="ExpoPride" />) : <SALogo />}
        </Link>
      </div>
    )
  }


  const Navigation = () => {
    const nav = navigation?.header?.navigation

    const NavElementInner = ({title, icon, url, items, iconStroke}) => {
      return (
        <div css={css`
          padding: 0.25rem .75rem;
          padding-left: 2em;
          display: inline-flex;
          align-items: center;
          position: relative;
          margin: 0 .1em;
          &:before {
            height: 100%;
            width: 100%;
            position: absolute;
            background: ${dark ? color.gray800 : color.plue25};
            top: 0;
            left: 0;
            border-radius: 5rem;
          }
        `}>
          <div css={[css`
            position: absolute;
            top: 50%;
            left: 1em;
            transform: translateY(-50%);
            svg {
              fill: ${dark ? color.white : color.main_dark };
              width: .75em;
              height: auto; 
            }
          `, iconStroke ? css`
            svg {
              fill: none;
              stroke: ${dark ? color.white : color.main_dark }; 
            }
          ` : null]}>
            {icon}
          </div>
          <p css={[css`
            font-size: .85em;
            position: relative;
            margin-bottom: 0;
            color: ${dark ? color.white : color.main_dark };
          `, url ? (
            css`
              &:after {
                content: "›";
                margin-left: 0.25em;
              }
            `
          ) : null]}>{title}</p>
          {items ? (
            <svg
              css={css`
                fill: ${dark ? color.white : color.main_dark};
                width: auto;
                position: relative;
                height: 1em;
                margin-left: .25em;
                margin-right: -.25em;
              `}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="1"
            >
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
            </svg>
          ) : null}
        </div>
      ) 
    }
    
    const NavLinkElement = ({item}) => {
      return (
        <li css={css`
          margin: 0;
          position: relative;
        `}>
          <Link to={item.url} getProps={getProps} css={css`
            margin-bottom: 0;
            text-decoration: none;
            &[data-active]>*:before {
              content: '';
            }
          `}>
            <NavElementInner
              title={item.title}
              icon={item.icon}
              url={item.url && item.url.length > 0 ? true : false}
              iconStroke={item.iconStroke}
             /> 
          </Link>
        </li>
      )
    }


    const NavDropdownElement = ({item}) => {
      const [activeDropdown, setActiveDropdown] = useState(false);
      const [dropdownOffset, setDropdownOffset] = useState(0);
      
      const dropdownRef = useRef(null);
      const triggerRef = useRef(null)

      const toggleDropdown = useCallback(() => {
        setActiveDropdown(activeDropdown => !activeDropdown);

        if (triggerRef.current.getAttribute("data-active") === null) {
          triggerRef.current.setAttribute("data-active", true) 
        } else {
          triggerRef.current.removeAttribute("data-active")
        }
      }, [activeDropdown]);

      const activateDropdown = useCallback(() => {
        if ( !(activeDropdown) ) {
          setActiveDropdown(true)
          triggerRef.current.setAttribute("data-active", true)
        }
      }, [activeDropdown])
      
      const deactivateDropdown = useCallback(() => {
        if ( activeDropdown ) {
          setActiveDropdown(false)
          triggerRef.current.removeAttribute("data-active")
        }
      }, [activeDropdown])

      // position the dropdowns
      useEffect(() => {
        let dropdownWidth = parseFloat(window.getComputedStyle(dropdownRef.current.firstChild).width);
        let triggerPosition = triggerRef.current.getBoundingClientRect()
        let triggerPositionMiddle = triggerPosition.left + (triggerPosition.width / 2) 
        let windowWidth = window.innerWidth
        let boxedWindowWidth = windowWidth  - (2 * 16)

        let offsetLeft = (dropdownWidth/2) + 16 - (triggerPosition.left + (triggerPosition.width / 2))

        let offsetRight = (dropdownWidth/2) - (windowWidth - 16 - triggerPositionMiddle)

        if (offsetLeft > 0 && dropdownWidth <= boxedWindowWidth && offsetLeft < dropdownWidth / 2) {
          setDropdownOffset(offsetLeft)
        } else if (offsetLeft < 0 && offsetRight > 0 && dropdownWidth <= boxedWindowWidth && offsetRight < dropdownWidth / 2) {
          setDropdownOffset(-(offsetRight))
        } else {
          setDropdownOffset(0)
        }
      }, [])

      return (
        <li 
          onMouseEnter={activateDropdown}
          onMouseLeave={deactivateDropdown}
          onKeyDown={toggleDropdown}
          onKeyPress={toggleDropdown}
          onClick={toggleDropdown}
          role="menuitem"
          aria-label="Show Dropdown"
          tabIndex="0" 
          css={css`
            margin: 0;
            position: relative;
          `}
        >
          <button
            ref={triggerRef}
            css={css`
              background: none;
              border: none;
              display: inline-flex;
              align-items: center;
              cursor: pointer;
              padding: 0;
              &[data-active]>*:before {
                content: '';
              }
            `
            }
          >
            <NavElementInner
              title={item.title}
              icon={item.icon}
              iconStroke={item.iconStroke}
              items={item.items && item.items.length > 0 ? true : false}
             /> 
            
          </button>
          <Dropdown items={item.items} active={activeDropdown} ref={dropdownRef} offset={dropdownOffset} />
        </li>
      )
    }


    const Dropdown = React.forwardRef(({items, active, offset}, ref) => {
      
      return (
        <div
          ref={ref}
          css={css`
            position: absolute;
            top: 100%;
            left: 50%;
            transform: ${`translateX(calc(-50% + ${offset}px))`};
            padding-top: 0.75rem;
            display: ${active ? "block" : "none"};
            &:before {
              position: absolute;
              top: 0.25rem;
              left: 50%;
              width: 1rem;
              height: 1rem;
              content: " ";
              //transform: translateX(calc(-50% - 0px)) rotate(45deg);
              transform: ${`translateX(calc(-50% - ${offset}px)) rotate(45deg)`};
              border-top-left-radius: 2px;
              background: ${color.white};
              will-change: transform;
              transition-property: transform;
            }
          `}
        >
          <ul
            css={css`
              background: ${color.white};
              box-shadow: 0px 4px 16px rgb(46 41 51 / 8%),
                0px 8px 24px rgb(71 63 79 / 16%);
              border-radius: 5px;
              overflow: hidden;
              list-style: none;
              margin: 0;
              width: 30rem;
            `}
          >
            {items.map((el, index) => (
              <li
                key={index}
                css={css`
                  background: ${el.background
                    ? el.background
                    : "none"};
                  margin-bottom: 0;
                  position: relative;
                `}
              >
                {el.url ? (
                  <Link
                    key={index}
                    to={el.url}
                    css={css`
                      text-decoration: none;
                    `}
                  >
                    <DropdownItem el={el} index={index} items={items} />
                  </Link>
                ) : (
                  <DropdownItem
                    css={css`
                      opacity: ${el.items && el.items.length > 0 ? 1 : .35};
                    `}
                    el={el}
                    index={index}
                    items={items}
                  />
                )}

                {el.items && el.items.length > 0 ? (
                  <DropdownList el={el} index={index} items={items} />
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      )
    })


    if (nav && nav.length > 0) {
      return (
        <div css={css`
          display: none;
          ${mq[2]} {
            display: block;
            flex: 1 0 auto;
          }
        `}>
          {nav && nav.length > 0 && (
            <ul css={css`
              list-style: none;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin: 0 2em 0 0;
            `}>
              {nav.map((el, index) => (
                
                el.items && el.items.length > 0 ? (
                  <NavDropdownElement item={el} key={index} />
                ) : (
                  <NavLinkElement item={el} key={index} />
                )

              ))}
            </ul>
          )}
        </div>
      )
    } else return null;
  }

  const CallToAction = () => {

    const calcPlacing = (index) => {
      const i = index;
      const l = navigation.header.cta.filter(el => !(el.active !== undefined && el.active === false)).length - 1;
  
      if ( i === 0 && l !== 0) {
        return css`
          &>button,
          &>a {
            margin-right: .1em;
            &:before {
              border-top-left-radius: 5px;
              border-top-right-radius: 0;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 0;
            }
          }
        `
      } else if ( i !== 0 && l !== 0 && i === l) {
        return css`
          &>button,
          &>a {
            margin-left: .1em;
            &:before {
              border-top-left-radius: 0;
              border-top-right-radius: 5px;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 5px;
            }
          }
        `
      } else if ( i !== 0 && i !== l) {
        return css`
          &>button,
          &>a {
            margin-left: .1em;
            margin-right: .1em;
            &:before {
              border-radius: 0;
            }
          }
        `
      } else return null;
    }
  
    const PlaceButton = ({e}) => {
      if (e.type) {
        if (e.type === "raw") {
          return e.content;
        } else if (e.type === "link") {
          return <IconButton to={e.url} name={e.title} css={[css`
            padding: .35rem .8rem;
            display: inline-block;
            letter-spacing: 0.025em;
            font-weight: 600;
            &:before {
              background: ${dark ? color.plue300 : color.plue400};
            }
          `, e.customColor ? (
            css`
              &:before {
                background: ${dark ? e.customColor.dark : e.customColor.light};
              }
            `
          ) : null]} />
        } else return null;
      } else return null;
    }
    
    if (navigation?.header?.cta && navigation.header.cta.length > 0) {
      
      return (
        <div css={css`
          margin-left: auto;
          overflow: hidden;
          display: flex;
          flex: 0 1 auto;
          // flex: 1 0 auto;
          justify-content: flex-end;
        `}>
          <ul css={css`
            list-style: none;
            min-width: 0;
            margin: 0;
            display: flex;
          `}>
            {navigation.header.cta.filter(el => !(el.active !== undefined && el.active === false)).map((e, index) => (
              <li key={index} css={[calcPlacing(index), css`
                margin-bottom: 0;
                display: inline-flex;
                align-items: center;
                min-width: 0;
                button {
                  white-space: nowrap;
                  display: inline-block;
                  text-overflow: ellipsis;
                  max-width: 100%;
                  overflow: hidden;
                  &:before {
                    background: ${dark ? color.plue300 : color.plue400};
                  }
                }
              `]}>
                <PlaceButton e={e} />
              </li>
            ))}
          </ul>
        </div>
      )
    } else return null;
  }



  return (
    <nav css={css`
      display: flex;
      padding: 0 .1em;
      align-items: center;
      justify-content: space-between;
    `}>
      <Logo />
      <div css={css`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        min-width: 0;
      `}>
        <Navigation />
        <CallToAction />
      </div>
    </nav>
  )
}


export const DropdownItem = ({el, index, items, className}) => {
  return (
    <div
      className={className}
      css={css`
        padding: ${index === 0 ? "2em 2em 1em" : "1em 2em"};
        ${mq[2]} {
          padding: ${index === 0 ? "2em 2em .75em" : index === items.length - 1 ? ".75em 2em 2em" : ".75em 2em"};
        }
      `}
    >
      {el.title ? (
        <h2 css={[css`
          font-size: 1em;
          margin-bottom: .25em;
          letter-spacing: .01em;
          line-height: 1.4em;
          color: ${color.main_dark};
          font-weight: 600;
        `, el.url ? (
          css`
            &:after {
              content: "›";
              margin-left: .25em;
            }
          `
        ) : null]}>
          {el.title}
        </h2>
      ) : null}
      {el.teaser ? (<p css={css`
        font-size: .75em;
        line-height: 1.6em;
        letter-spacing: .02em;
        color: ${color.gray600};
        margin-bottom: 0;
      `}>{el.teaser}</p>) : null}
    </div>
  )
}

export const DropdownList = ({el, index, items}) => {
  
  const DropdownListEl = ({el}) => {
    return(
      <li css={css`
        margin: 0;
        &:nth-of-type(odd) {
          padding-right: .5em;
        }
        &:nth-of-type(even) {
          padding-left: .5em;
        }
        p, a {
          font-size: .75em;
          font-weight: 500;
          line-height: 1.4em;
          letter-spacing: .01em;
          color: ${color.gray600};
          display: inline-block;
        }
      `}>
        {el.url ? (
          <Link
            to={el.url}
            css={css`
              text-decoration: none;
              &:after {
                content: "›";
                margin-left: .25em;
              }
            `}
          >
            {el.title}
          </Link>
        ) : <p css={css`
          margin-bottom: 0;
          opacity: .5;
        `}>{el.title}</p> }
      </li>
    )  
  }

  return (
    <ul css={css`
      list-style: none;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      margin: -.75em 2em 0;
      margin-top: ${index === items.length - 1 ? "-1.5em" : "-.25em"};
      &:last-of-type {
        padding-bottom: ${index === items.length - 1 ? "2em" : ".75em"};
      }
    `}>
      {el.items.map((item, index) => (
        <DropdownListEl el={item} key={index} />
      ))}
    </ul>
  )
}


const PlueNavigation = ({...props}) => {
  const { navigationBackground, dark } = props;

  const [headerActive, setHeaderActive] = useState(false);
  const preHeader = useRef(null);
  const [preHeight, setPreHeight] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  })


  // debounce function
  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  
  useEffect(() => {
    const debouncedHandleResize = debounce(
      () => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        })
      }
    , 1000)
    
    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }

  }, [])



  useEffect(() => {
    let height = preHeader.current.offsetHeight
    setPreHeight(height);
  }, [dimensions])

  useEffect(() => {
    const offset = preHeight;
    const handleScroll = () => {
      if ( window.scrollY >= offset )  {
        setHeaderActive(true);
      } else {
        setHeaderActive(false);
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [preHeight])

  return (
    <>
    <div css={css`
      display: flex;
      // this is a positioning hack as otherwise the navigationbar
      // on the login screen would not be placed at the top
    `} />
    <div css={css`
      position: relative;
      top: 0;
      left: 0;
      z-index: 9;
      -webkit-font-smoothing: auto;
      -moz-osx-font-smoothing: auto;
      font-smoothing: auto;
      ${mq[2]} {
        position: sticky;
        top: ${"-" + preHeight + "px"};
      }
    `}>
      <div className={headerActive ? "active" : null} css={css`
        position: absolute;
        width: 100%;
        background: ${navigationBackground ? color.white : "none"};
        ${mq[1]} {
          background: none;
          &:before {
            backdrop-filter: saturate(180%) blur(8px);
            background-color: ${dark ? "rgba(29, 29, 31, 0.7)" : "rgba(255, 255, 255, 0.7)"};
            position: absolute;
            height: 100%;
            width: 100%;
            content: '';
            opacity: 0;
            transition: opacity .2s ease-in-out;
          }
          &.active:before {
            opacity: 1;
          }
        }
      `}>
        <div css={wrapper}>
          <PreHeader dark={dark} preHeaderRef={preHeader} />

          <Header dark={dark} dimension={dimensions}  />
        </div>
      </div>
    </div>
    </>
  )
}

export default PlueNavigation;